import Errors from "../errors";


export default {
    requestError: (state, err) => {
        if (err) {
            state.errors = err
        } else {
            state.errors = new Errors()
        }
    },
    requestSuccess: (state, resp) => {
        state.status = 'success'
    },
    setDashBoardData: (state, resp) => {
        state.dashBoardData = resp;
    },
}
