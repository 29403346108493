import authHeader from "../auth/auth-header";
import {reactive, inject, computed, ref, watch} from "vue";
import {useStore} from "vuex";
import {
    createPermission,
    deletePermission,
    statusPermission,
    updatePermission,
    viewPermission
} from "@/common/helpers/permission.helper";
import {useRouter} from "vue-router";
import Cookies from 'js-cookie'
import moment from 'moment'


export default function () {
    const store = useStore();
    const axios = inject('axios')
    const toast = inject('toast')
    const swal = inject('$swal')
    const resetForm = ref(null);
    const router = useRouter();

    let data = reactive({
        dataList: [],
        pageLimit: '10',
        showForm: false,
        dropdownData: [],
        inputFormData: {},
        getRequestFile: {},
        pageSize: ['10', '25', '50', '100', 'All'],
        setURL: "",
        imageUrl: "",
        formError: {},
        permission: {
            canCreate: false,
            status: false,
            canEdit: false,
            canDelete: false
        }
    });

    async function openModal(modalId, dataURL = false, params = false) {
        resetFormData()
        if (modalId) $("#" + modalId).modal('show') // eslint-disable-line
        if (dataURL) {
            let setParams = {}
            if (params) {
                setParams = {
                    params: {
                        ...params
                    }
                }
            }
            data.dropdownData = []
            return new Promise((async (resolve, reject) => {
                await axios.get(dataURL, params ? Object.assign(setParams, authHeader()) : authHeader())
                    .then((response) => {
                        if (response.data.data) {
                            data.dropdownData = response.data.data
                        }
                        resolve(response)
                    })
                    .catch((error) => {
                        showToast(error)
                        reject(error)
                    })
            }))
        }
    }

    function getAllData(url, options = false) {
        data.pageLimit = options.limit ? options.limit : data.pageLimit //set page limit on change
        const params = getParams(options)
        return new Promise((async (resolve, reject) => {
            axios.get(url, options ? params : authHeader())
                .then((response) => {
                    data.dataList = response.data.data
                    resolve(response)
                })
                .catch((error) => {
                    showToast(error)
                    reject(error)
                })
        }))
    }

    function getFormFile(event, fileName) {
        data.getRequestFile[fileName] = event.target.files
        data.imageUrl = URL.createObjectURL(event.target.files[0])
    }

    async function storeFormData(obj, formData = {}, options = false, setParams = false,
            staticData = false, createAxios = false, showMessage = true) {

        let configHeaders = {}
        let formReqFile = formData
        if (Object.keys(data.getRequestFile).length > 0) {
            formReqFile = new FormData()
            let i = 0
            for (let [index, val] of Object.entries(data.getRequestFile)) {
                formReqFile.append(index, val[i])
                i++
            }
            if (Object.keys(formData).length > 0) {
                Object.keys(formData).forEach((key) => {
                    formReqFile.append(key, formData[key])
                });
            }
            configHeaders = {
                headers: {
                    Authorization: Cookies.get("access_token"),
                    "Content-Type": "multipart/form-data",
                },
            };
        }
        return new Promise((async (resolve, reject) => {
            const conditionalAxios = createAxios ? axios.create() : axios

            await conditionalAxios.post(obj.url, formReqFile, Object.keys(data.getRequestFile).length > 0 ? configHeaders : authHeader())
                .then((response) => {
                    if (response) {
                        if (options) {
                            editFormData({url: options})
                        }
                        if (setParams) {
                            getAllData(data.setURL, setParams)
                        }
                        if (staticData) {
                            store.dispatch('reloadStaticData');
                        }
                        if(showMessage) showToast(response)
                        if (obj.modalId) $("#" + obj.modalId).modal('hide') // eslint-disable-line
                        resolve(response)
                    }
                })
                .catch( async (err) => {
                    // CALL TOAST
                    if(showMessage) showToast(err)

                    // CALL REFRESH TOKEN ACTION
                    const originalRequest = err.config;
                    if(createAxios && err.response && err.response.status === 401 && !originalRequest._retry) {
                        originalRequest._retry = true
                        const unInterceptedAxiosInstance = axios.create()
                        let refreshToken = Cookies.get("refresh_token")
                        try {
                            const resp = await store.dispatch("refreshAction", { formData: refreshToken, action: "refresh" })
                            
                            if (resp.status === 200) {
                                originalRequest.headers['Authorization'] = Cookies.get("access_token")
                                return unInterceptedAxiosInstance(originalRequest)
                            }
                        } catch (_error) {
                            return Promise.reject(_error)
                        }
                    }

                    reject(err)
                })
        }))

    }

    async function editFormData(object, dataURL = false, callback) {
        const params = object.params ? getParams(object.params) : {}
        // resetFormData()
        if (dataURL) {
            data.dropdownData = []
            await axios.get(dataURL, authHeader())
                .then((response) => {
                    if (response.data.data) {
                        data.dropdownData = response.data.data
                    }
                })
                .catch((error) => {
                    showToast(error)
                })
        }
        return new Promise((async (resolve, reject) => {
            await axios.get(object.url, object.params ? params : authHeader())
                .then((response) => {
                    data.inputFormData = response.data.data
                    if (typeof callback === 'function') {
                        callback(data.inputFormData)
                    }
                    if (object.modalId) $("#" + object.modalId).modal('show') // eslint-disable-line
                    resolve(response)
                })
                .catch(async (error) => {
                    // CALL REFRESH TOKEN ACTION
                    const originalRequest = error.config;
                    if(error.response && error.response.status === 401 && !originalRequest._retry) {
                        originalRequest._retry = true
                        const unInterceptedAxiosInstance = axios.create()
                        let refreshToken = Cookies.get("refresh_token")
                        try {
                            const resp = await store.dispatch("refreshAction", { formData: refreshToken, action: "refresh" })
                            
                            if (resp.status === 200) {
                                originalRequest.headers['Authorization'] = Cookies.get("access_token")
                                return unInterceptedAxiosInstance(originalRequest)
                            }
                        } catch (_error) {
                            return Promise.reject(_error)
                        }
                    }

                    showToast(error)
                    reject(error)
                })
        }))
    }

    async function deleteListData(url, setParams = false) {
        return new Promise((async (resolve, reject) => {
            swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed === true) {
                    await axios.delete(url, authHeader(setParams))
                        .then((response) => {
                            swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                            if (setParams) getAllData(data.setURL, setParams)
                            resolve(response)
                        })
                        .catch((error) => {
                            showToast(error)
                            reject(error)
                        })
                }
            })
        }))
    }

    function resetFormData() {
        data.inputFormData = {}
        data.getRequestFile = {}
        if (resetForm.value != null) {
            setTimeout(() => {
                resetForm.value.resetForm()
            }, 100)
        }
    }

    function showToast(info) {
        //SUCCESS
        if (info.data && [200, 201].includes(info.status)) {
            const { message } = info.data;
            switch (message) {
                case "response.success.store":
                    toast.success("Created Successfully");
                    break;
                case "response.success.update":
                    toast.success("Updated Successfully");
                    break;
                case "response.success.delete":
                    toast.success("Deleted Successfully");
                    break;
                default:
                    toast.success(message);
            }
        } else {
            //ERRORS
            if (info.response && info.response.data.errors && (info.response.status >= 400 && info.response.status < 500)) {
                // SHOW BACKEND ERRORS
                const errorKVP = info.response.data ? info.response.data.errors : [];
                const errors = Object.values(errorKVP)
                errors.forEach(error => {
                    toast.error(error.toString())
                })
            } else if (info.response && info.response.status === 500) {
                toast.error(info.response.data ? info.response.data.message : info.response.statusText)
            } else {
                toast.warning(info.data?.message ?? info.statusText)
            }
        }
    }

    function getParams(param) {
        let setParams = {
            params: {
                ...param,
                // max_count: param.check ? null : data.pageLimit,
                max_count: data.pageLimit == -1 ? null : data.pageLimit,
                sort: param.sort ? param.sort : 'desc',
                column: param.column ? param.column : 'id',
                company_id: param.company_id ? param.company_id : null,
                page: param.page ? param.page : null,
            }
        }
        return Object.assign(setParams, authHeader())
    }

    async function downloadFile(payload) {
        return new Promise((async (resolve, reject) => {
            let setParams = {
                params: {
                    ...payload.FormData,
                },
                responseType: 'blob'
            }
            await axios.get(payload.action, Object.assign(setParams, authHeader()))
                .then((resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    showToast(err);
                    reject(err);
                });
        }))
    }

    async function downloadDocxFile(payload) {
        return new Promise((async (resolve, reject) => {
            let setParams = {
                params: {
                    ...payload.FormData,
                },
            }
            await axios.get(payload.action, Object.assign(setParams, authHeader()))
                .then((resp) => {
                    resolve(resp);
                })
                .catch((err) => {
                    showToast(err);
                    reject(err);
                });
        }))
    }

    const siteUrl = (url) => {
        return process.env.VUE_APP_BACKEND_URL + url;
    };

    // DEBOUNCE REQUEST FOR 500ms
    function debounceEvent(func, timeout = 500){
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    /** computed **/
    const selectedCompany = computed(() => store.getters.getSelectedBoard);
    const selectedMenu = computed(() => store.getters.getSelectedMenu);
    const getTemplateCategory = computed(() => store.getters.getTemplateCategory);
    const getFormErrors = computed(() => {
        let output = {};
        Object.keys(data.formError).forEach((key) => {
            if (data.formError[key]) {
                output[key] = data.formError[key][0]
            }
        });
        return output;
    })

    /** computed **/
    function permissionResolver(route, thirdLayer = false, actions = ['update', 'delete', 'create', 'status']) {
        watch(() => selectedMenu, (menu) => {
            if (menu.value) {
                let childMenus = menu.value.hasOwnProperty('menus') ? menu.value.menus : [];
                let childMenu = null;
                childMenus.find(item => {
                    if (item) {
                        if (thirdLayer && item.menus.length > 0) {
                            let miniChild = item.menus.find(miniItem => miniItem && miniItem.route_link === route);
                            if (miniChild) childMenu = miniChild;
                        } else if (route === item.route_link) {
                            childMenu = item;
                        }
                    }
                });

                if (childMenu) {
                    const childMenuActions = childMenu.actions;
                    permissionCheckerTopAndChildMenu(childMenuActions, actions);
                } else {
                    let topMenuActions = menu.value.hasOwnProperty('actions') ? menu.value.actions : [];
                    permissionCheckerTopAndChildMenu(topMenuActions, actions);
                }
            }
        }, {immediate: true});
    }

    function permissionCheckerTopAndChildMenu(menuActions, actions) {
        let view = viewPermission(menuActions);
        if (!view) {
            router.push(`/dashboard`);
        }

        if (actions.includes('update')) {
            let editPermission = updatePermission(menuActions);
            data.permission.canEdit = editPermission ? editPermission.show : false;
        }

        if (actions.includes('create')) {
            let addPermission = createPermission(menuActions);
            data.permission.canCreate = addPermission ? addPermission.show : false;
        }

        if (actions.includes('delete')) {
            let removePermission = deletePermission(menuActions);
            data.permission.canDelete = removePermission ? removePermission.show : false;
        }

        if (actions.includes('status')) {
            let status = statusPermission(menuActions);
            data.permission.status = status ? status.show : false;
        }
    }

    function formPermissionResolver(route, thirdLayer = false, action = ['update', 'create']) {
        permissionResolver(route, thirdLayer, action, true);
    }

    function getToDate(tomorrow = false, yesterday = false) {
        let now = new moment(Date.now())
        if(tomorrow)
        {
            return moment(now).add(1,'days').format('YYYY-MM-DD');
        }
        else if(yesterday) 
        {
            return moment(now).add(-1, 'days').format('YYYY-MM-DD');
        }
        else
        {
            return moment(now).format('YYYY-MM-DD');
        }
        
    }
    
    function getCurrentYear() {
        let now = new moment(Date.now())
        return moment(now).format('YYYY');
    }

    function getFormatedMileStone() {
        let array = [
            "At the end of this month",
            "At the beginning of next month",
            "At the end of this year",
            "At the end of next year",
            "At the beginning of next year",
            // "At the end of First Quarter " + this.getCurrentYear(),
            // "At the end of Second Quarter " + this.getCurrentYear(),
            // "At the end of Third Quarter " + this.getCurrentYear(),
            // "At the end of Fourth Quarter " + this.getCurrentYear(),
            // "At the beginning of First Quarter " + this.getCurrentYear(),
            // "At the beginning of Second Quarter " + this.getCurrentYear(),
            // "At the beginning of Third Quarter " + this.getCurrentYear(),
            // "At the beginning of Fourth Quarter " + this.getCurrentYear(),
            "In the middle of this year",
            "In the middle of next year",
        ]

        for (let i = 1; i <= 12; i++ ) {
            let dateVal = `2020-${String(i).padStart(2, '0')}-01`
            array.push(`At the end of ${moment(dateVal).format('MMMM')} ${getCurrentYear()}`)
            array.push(`At the beginning of ${moment(dateVal).format('MMMM')} ${getCurrentYear()}`)
        }

        return array.map(text => text.toUpperCase())
    }

    return {
        data,
        getAllData,
        openModal,
        editFormData,
        deleteListData,
        getFormFile,
        storeFormData,
        showToast,
        getParams,
        selectedCompany,
        siteUrl,
        getTemplateCategory,
        downloadFile,
        downloadDocxFile,
        getFormErrors,
        resetForm,
        selectedMenu,
        permissionResolver,
        formPermissionResolver,
        debounceEvent,
        getFormatedMileStone,
        getToDate
    }
}
