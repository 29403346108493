import axios from "axios";
import authHeader from "@/common/auth/auth-header";
import Cookies from 'js-cookie';

export default {
    userAction: ({commit, dispatch}) => {
        return new Promise((resolve, reject) => {
            const localUser = JSON.parse(localStorage.getItem('user_info'));
            if (localUser) {
                commit('userSuccess', localUser);
                resolve(localUser);
                return;
            }
            axios.get(vueConfig.User.loggedInUserEndPoint, authHeader())
                .then(resp => {
                    const userInfo = resp.data.data;
                    localStorage.setItem('user_info', JSON.stringify(userInfo));
                    commit('userSuccess', userInfo);
                    resolve(resp.data)
                })
                .catch((err) => {
                    commit('userError');
                    dispatch('authLogout')
                    reject(err)
                })
        })
    },

    staticDataRequest: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(vueConfig.Static.StaticDataEndPoint, payload, authHeader())
                .then(resp => {
                    commit('staticDataSuccess', resp.data.data)
                    resolve(resp.data)
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                })
        })
    },

    updateStaticState: ({commit}, payload) => {
        return new Promise(async (resolve, reject) => {
            await axios.post(vueConfig.Static.StaticDataUpdateEndPoint, payload)
                .then(resp => {
                    commit('staticDataSuccess', {})
                    resolve({})
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                })
        })
    },

    getUsersByEmail: ({commit, dispatch}, payload) => {
        const unInterceptedAxiosInstance = axios.create();
        return new Promise((resolve, reject) => {
            unInterceptedAxiosInstance.get(payload.action, authHeader())
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(async (err) => {
                    // CALL REFRESH TOKEN ACTION
                    const originalRequest = err.config;
                    if(err.response && err.response.status === 401 && !originalRequest._retry) {
                        originalRequest._retry = true
                        let refreshToken = Cookies.get("refresh_token")
                        try {
                            const resp = await dispatch("refreshAction", { formData: refreshToken, action: "refresh" })
                            
                            if (resp.status === 200) {
                                originalRequest.headers['Authorization'] = Cookies.get("access_token")
                                return unInterceptedAxiosInstance(originalRequest)
                            }
                        } catch (_error) {
                            return Promise.reject(_error)
                        }
                    }

                    commit('userError');
                    reject(err)
                })
        })
    },

    getCompanyUser: ({commit, dispatch}, payload) => {
        const unInterceptedAxiosInstance = axios.create();
        return new Promise((resolve, reject) => {
            unInterceptedAxiosInstance.get(payload.action, authHeader())
                .then(resp => {

                    resolve(resp.data)
                })
                .catch( async (err) => {
                    // CALL REFRESH TOKEN ACTION
                    const originalRequest = err.config;
                    if(err.response && err.response.status === 401 && !originalRequest._retry) {
                        originalRequest._retry = true
                        let refreshToken = Cookies.get("refresh_token")
                        try {
                            const resp = await dispatch("refreshAction", { formData: refreshToken, action: "refresh" })
                            
                            if (resp.status === 200) {
                                originalRequest.headers['Authorization'] = Cookies.get("access_token")
                                return unInterceptedAxiosInstance(originalRequest)
                            }
                        } catch (_error) {
                            return Promise.reject(_error)
                        }
                    }
                    commit('userError');
                    reject(err)
                })
        })
    },


    changeBoard: ({commit, dispatch, state}, payload) => {
        return new Promise(async (resolve, reject) => {
            try {
                const userInfo = state.profile;
                const board = payload.board;
                const company = {
                    company_id: board.id ? board.id : null
                };
                localStorage.setItem('selected_board', JSON.stringify(board));

                commit('selectedBoard', board);

                if (userInfo.superAdmin) {
                    await dispatch('getMasterData');
                } else {
                    await dispatch('getBoardUserInfo', company);
                    await dispatch('getBoardRoles', company);
                    await dispatch('getBoardItems', company);
                }
                const menu = await dispatch('getBoardUserMenu', {company: company, route: payload.route});
                await dispatch('getDashboardData', company);
                await dispatch('getTemplateCategory', company);

                resolve(menu);

            } catch (err) {
                dispatch('removeCommonLocalStorageItem')
                commit('loginError', err.response)
                reject(err.response)
            }
        })
    },

    getMasterData: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            axios.get(vueConfig.Master.InfoEndPoint, authHeader())
                .then(resp => {
                    commit('masterData', resp.data.data)
                    resolve(resp.data)
                })
                .catch((err) => {
                    dispatch('removeCommonLocalStorageItem')
                    commit('userError');
                    reject(err)
                })
        })
    },
    reloadStaticData:({dispatch}) => {
        const selectedBoard = JSON.parse(localStorage.getItem('selected_board'));
        let payload = {
            board: selectedBoard,
            route: ''
        };
        dispatch('changeBoard', payload);
    },
    getBoardUserMenu: ({commit, dispatch}, payload) => {
        return new Promise(async (resolve, reject) => {
            await axios.post(vueConfig.Board.MenusEndPoint, payload.company, authHeader())
                .then(resp => {
                    let result = resp.data.data;
                    commit('boardUserMenuData', result);
                    localStorage.setItem('board_menus', JSON.stringify(result));
                    resolve(resp);
                })
                .catch((err) => {
                    dispatch('removeCommonLocalStorageItem')
                    commit('userError');
                    reject(err)
                })
        })
    },
    getBoardUserInfo: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(vueConfig.Board.UserInfoEndPoint, payload, authHeader())
                .then(resp => {
                    commit('boardUserInfoData', resp.data.data)
                    resolve(resp.data)
                })
                .catch((err) => {
                    dispatch('removeCommonLocalStorageItem')
                    commit('userError');
                    reject(err)
                })
        })
    },
    getBoardRoles: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(vueConfig.Board.RolesEndPoint, payload, authHeader())
                .then(resp => {
                    commit('boardRolesData', resp.data.data)
                    resolve(resp.data)
                })
                .catch((err) => {
                    dispatch('removeCommonLocalStorageItem')
                    commit('userError');
                    reject(err)
                })
        })
    },
    getBoardItems: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(vueConfig.Board.ItemsEndPoint, payload, authHeader())
                .then(resp => {
                    commit('boardItemsData', resp.data.data)
                    resolve(resp.data)
                })
                .catch((err) => {
                    dispatch('removeCommonLocalStorageItem')
                    commit('userError');
                    reject(err)
                })
        })
    },
    changeMenu: ({commit}, payload) => {
        localStorage.setItem('selected_menu', JSON.stringify(payload));
        commit('changeMenu', payload);
    },

    removeCommonLocalStorageItem: ({commit}) => {
        commit('changeMenu', {});
        commit('selectedBoard', {});
        localStorage.removeItem("selected_menu");
        localStorage.removeItem("selected_board");
        localStorage.removeItem("board_menus");
    },

    changeStatus: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(payload.action, payload.formData, authHeader())
                .then(resp => {
                    resolve(resp.data)
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                })
        })
    },

    getTemplateCategory: ({commit, dispatch}, payload) => {
        return new Promise((resolve, reject) => {
            axios.get(vueConfig.Setup.TempCategoryEndPoint,
                Object.assign(payload, authHeader()))
                .then(resp => {
                    commit('templateCategory', resp.data.data)
                    resolve(resp.data)
                })
                .catch((err) => {
                    dispatch('removeCommonLocalStorageItem')
                    commit('userError');
                    reject(err)
                })
        })
    },

    getCompanies: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.get(vueConfig.Company.CompanyEndPoint,
                Object.assign(payload, authHeader()))
                .then(resp => {
                    resolve(resp.data)
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                })
        })
    },
}
