<template>
    <header class="top-header-wrapper">
        <nav class="top-header d-flex px-3 align-items-center bg-white">

            <div class="top-header-logo d-none d-sm-flex align-items-center me-auto">
                <a href="">
                    <img :src="loggedInUserInfo.photo ? `${loggedInUserInfo.photo}` : require('@/assets/img/logo-herzing.png')" alt="company-logo">
                </a>
            </div>

            <ul class="top-header-item-group d-none d-xl-flex mx-auto">
                <li v-for="(menu, key) in menuItems" :key="`menu-item-${key}`" class="top-header-item" :class="{'border-end': key != menuItems.length - 1}">
                    <router-link :to="`/${menu.route_link}`" :class="['d-inline-flex align-items-center', isRouteActive(menu.route_link)]" aria-current="page">
                        <img :src="require(`@/assets/img/${menu.icon}.svg`)" alt="" class="img-fluid svg-icon-menu me-2"/>
                        <span>{{ getTranslatedValue(menu.name) }}</span>
                    </router-link>
                </li>
            </ul>
                
            <div class="top-header-icon-group d-flex align-items-center ms-auto">

                <select
                    class="form-select form-select-sm language-custom-class" 
                    aria-label="Language-select"
                    v-model="selectedLanguage"
                    @change="handleLocaleChange(selectedLanguage)"
                >
                    <option
                    v-for="lang in languages"
                    :key="lang.id"
                    :value="lang.language"
                    >
                    {{ lang.language }}
                    </option>
                </select>

                <router-link :to="`/user-profile/${loggedInUserInfo.id}`" class="top-header-icon"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Profile"
                    v-if="Object.keys(selectedCompany).length > 0">
                    <font-awesome-icon :class="'p-1'" :icon="['fas', `user`]"></font-awesome-icon>
                </router-link>

                <a href="#" class="top-header-icon"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Notification">
                    <font-awesome-icon :class="'p-1'" :icon="['fas', `bell`]"></font-awesome-icon>
                </a>
                <!-- <div class="position-relative">
                    <button class="top-header-icon border-0" id="notificationDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                        <font-awesome-icon :class="'p-1'" :icon="['fas', `bell`]"></font-awesome-icon>
                        <span v-if="unreadCount > 0" class="notification-badge">{{ unreadCount }}</span>
                    </button>

                    <ul class="dropdown-menu border-0 shadow-sm" aria-labelledby="notificationDropdown" id="dropdown-profile" >
                        <li v-for="notification in notifications" :key="notification.id" class="dropdown-item">
                        <a href="#" @click="markAsRead(notification.id)">
                            {{ notification.data.message }}
                        </a>
                        </li>
                        <li v-if="notifications.length === 0" class="dropdown-item">No new notifications</li>
                    </ul>
                </div> -->

                <router-link 
                    v-if="!loggedInUserInfo.superAdmin"
                    :to="'/board'"
                    class="top-header-icon"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Board">
                    <font-awesome-icon :class="'p-1'" :icon="['fas', `columns`]"></font-awesome-icon>
                </router-link>

                <!--With Dropdown-->
                <div>
                    <button class="top-header-icon border-0" id="profileDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                        <font-awesome-icon :class="'img-fluid dropdown-toggle header-user-icon'" :icon="['fas', `user-circle`]"></font-awesome-icon>
                    </button>

                    <ul class="dropdown-menu border-0 shadow-sm" aria-labelledby="profileDropdown" id="dropdown-profile">
                        <li class="dropdown-item d-inline-flex align-items-center" v-if="Object.keys(selectedCompany).length > 0">
                            <img :src="require('@/assets/img/profile-pic.svg')" alt="avatar" class="dropdown-avatar me-2">
                            <span>{{loggedInUserInfo.name}}</span>
                        </li>
                        <li class="dropdown-item d-inline-flex align-items-center" v-if="Object.keys(selectedCompany).length > 0">
                            <font-awesome-icon :class="'me-2'" :icon="['fas', `envelope-open-text`]"></font-awesome-icon>
                            <span>{{loggedInUserInfo.email}}</span>
                        </li>
                        <li class="dropdown-item d-inline-flex align-items-center" @click="logoutUser">
                            <font-awesome-icon :class="'me-2'" :icon="['fas', `sign-out-alt`]"></font-awesome-icon>
                            <span>Logout</span>
                        </li>
                    </ul>
                </div>

                <div class="top-header-toggler d-block d-xl-none">
                    <button class="p-3 rounded-3 border-0 bg-white btn-toggler" @click="toggleMobileMenu">
                        <font-awesome-icon :icon="['fas', `bars`]"></font-awesome-icon>
                    </button>
                </div>
            </div>   
        </nav>
        
        <!-- Mobile menu -->
        <nav class="top-header-mini d-block d-xl-none bg-white border-top" :class="{collapsed: isActive}">
            <ul class="top-header-mini-items d-flex flex-column w-100 align-items-center">
                <li v-for="(menu, key) in menuItems" :key="`menu-item-${key}`" class="mini-nav-item">
                    <router-link :to="`/${menu.route_link}`" :class="['d-inline-flex align-items-center justify-content-center rounded-pill', isRouteActive(menu.route_link)]" aria-current="page">
                        <img :src="require(`@/assets/img/${menu.icon}.svg`)" alt="" class="img-fluid svg-icon-menu me-2" :class="{'d-block': !isActive, 'd-none': isActive}" />
                        <span :class="{'d-block': !isActive, 'd-none': isActive}">{{ menu.name }}</span>
                    </router-link>
                </li>
            </ul>   
        </nav>
        <!-- End mobile menu -->
    </header>

</template>

<script>
import {useStore} from "vuex";
import {computed, watch, onMounted} from "vue";
import {useRoute} from "vue-router";
import HelperFunction from "@/common/helpers";
import { ref } from "@vue/reactivity";
import axios from "axios";

export default {
    name: "TopHeader",
    setup() {
        const store = useStore();
        const route = useRoute();
        const { selectedCompany } = new HelperFunction()

        /** computed properties**/
        const menuItems = computed(() => store.getters.getMenuItems);
        const currentMenu = computed(() => store.getters.getSelectedMenu ? store.getters.getSelectedMenu : {});

        // FOR LOGGED IN USER INFO.
        const loggedInUserInfo = computed(() => store.getters.getProfile);
        /** computed properties end**/

        watch(() => route.path, (value) => {
            if (value) {
                const pathArr = value.split('/');
                if (menuItems.value) {
                    let selectedMenu = menuItems.value.find(item => {
                        return pathArr.includes(item.name.toLowerCase())
                    });
                    if (selectedMenu) {
                        store.dispatch('changeMenu', selectedMenu);
                    } else {
                        store.dispatch('changeMenu', {});
                    }
                }
            }
        }, {immediate: true});

            const showNotifications = ref(false);

            const notifications = computed(() => store.getters.allNotifications);
            const unreadCount = computed(() => store.getters.unreadCount);

            // Mark a notification as read
            const markAsRead = async (notificationId) => {
                await store.dispatch('markAsRead', notificationId);
            };


        function formatName(name) {
            const lowerCaseName = name.toLowerCase();
        
            if (lowerCaseName.includes('_')) {
                return lowerCaseName;
            } else {
                return lowerCaseName.replace(' ', '_'); 
            }
        }
        onMounted(() => {
            //store.dispatch('fetchNotifications'); 
            fetchLanguages();
        })
        let locale;
        const localeChangeKey = localStorage.getItem("locale_change_key");
        if(localeChangeKey) {
            locale = localStorage.getItem("locale_change_key");
        } else {
            locale = localStorage.getItem("locale") || "en";
        }
        
        const selectedLanguage = ref(locale ?? null);
        const languages = ref([]);
        const fetchLanguages = async () => {
            try {
                const response = await axios.get("/languages");
                languages.value = response.data;
            } catch (error) {
                console.error("Error fetching languages:", error);
            }
        };
        const handleLocaleChange =  async (locale) => {
            localStorage.setItem('locale_change_key', locale);
            localStorage.setItem('locale', locale);
            const fetchData = await fetchTranslation(locale);
            if (fetchData) {
                setTimeout(() => {
                    window.location.reload(); 
                }, 500);  
            }
        }

        const fetchTranslation = async (storedLocale) => {
            axios.get(`/translations/${storedLocale}`)
            .then(response => {
                store.commit('setTranslations', response.data);  
                store.commit('setLocale', storedLocale); 
                
            })
            .catch(error => {
                console.error('Error fetching translations:', error);
            });
        };

        function getTranslatedValue(name) {
            //console.log(name);
            const formattedName = formatName(name);
            
            const translations = computed(() => {
                return store.state.translations;  
            });


            //console.log(translations.value[formattedName])

            return translations.value[formattedName] || name;
        }

        //OnCLick
        function logoutUser() {
            //ACTION REQUEST
            store.dispatch("logoutAction");
        }

        //Onlick mobile menu button
        const isActive = ref(true);
        const toggleMobileMenu = () => {
            isActive.value = !isActive.value;
        }

        const isRouteActive = (routeName) => {
            if (route.name === routeName) {
                return 'top-header-item-active'
            } else if (currentMenu.value) {
                let {name} = currentMenu.value;
                if (name) {
                    if (routeName.includes(name.toLowerCase())) return 'top-header-item-active';
                }
            }
            return '';
        }

        return {
            logoutUser,
            menuItems,
            toggleMobileMenu, isActive,
            isRouteActive, loggedInUserInfo, selectedCompany,getTranslatedValue, fetchLanguages, languages, selectedLanguage, handleLocaleChange,notifications,
            unreadCount,
            showNotifications,
            markAsRead
        };
    },
};
</script>
<style>
.language-custom-class {
    width: 70px;
    text-transform: uppercase;
}

</style>

<style scoped>
/* Notification Badge */
.notification-badge {
    position: absolute;
    top: 0;
    right: 13px;
    background-color: #cc4b4b;
    color: white;
    border-radius: 50%;
    padding: 0.2em 0.5em;
    font-size: 12px;
    width: 20px;
    height: 20px;
}

/* Notification Dropdown */
.notification-dropdown {
  position: absolute;
  right: 0;
  top: 40px;
  width: 300px;
  background-color: white;
  list-style: none;
  padding: 0;
  z-index: 10;
}

.notification-dropdown .dropdown-item {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.notification-dropdown .dropdown-item:hover {
  background-color: #f8f9fa;
}
</style>
