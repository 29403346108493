import state from '@/store/modules/dashboard/state'
import getters from '@/store/modules/dashboard/getters'
import mutations from '@/store/modules/dashboard/mutations'
import actions from '@/store/modules/dashboard/actions'

export default {
    state,
    getters,
    mutations,
    actions
}
