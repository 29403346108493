
export default {

    userSuccess: (state, resp) => {
        state.status = 'success';
        state.profile = resp;
    },
    staticDataSuccess: (state, resp) => {
        state.status = 'success';
        state.staticData = resp
    },
    boardError: (state) => {
        state.status = 'error'
    },
    userError: (state) => {
        state.status = 'error'
    },
    selectedBoard: (state, obj) => {
        state.selectedBoard = obj;
    },
    masterData: (state, resp) => {
        if (resp) {
            // subscription_types
            state.subscriptionTypes = resp.subscription_types ? resp.subscription_types : []
            // documents
            state.documents = resp.documents ? resp.documents : []
            // glossary
            state.glossary = resp.glossary ? resp.glossary : []
            // users
            state.users = resp.users ? resp.users : []
            // roles
            state.roles = resp.roles ? resp.roles : []
        }
    },
    boardUserMenuData: (state, resp) => {
        state.menuItems = resp ? resp : []
    },
    boardUserInfoData: (state, resp) => {
        if (resp) {
            // company
            state.company = resp.company ? resp.company : []
            // user_roles
            state.userRoles = resp.user_roles ? resp.user_roles : []
        }
    },
    boardRolesData: (state, resp) => {
        if (resp) {
            // console.log(resp, 'boardRolesData');
            // userRoles
            state.roles = resp.roles ? resp.roles : []
            // projectRoles
            state.projectRoles = resp.projectRoles ? resp.projectRoles : []
        }
    },
    boardItemsData: (state, resp) => {
        if (resp) {
            // documents
            state.documents = resp.documents ? resp.documents : []
            // glossary
            state.glossary = resp.glossary ? resp.glossary : []
            // employees
            state.employees = resp.employees ? resp.employees : []
            // milestones
            state.milestones = resp.milestones ? resp.milestones : []
            //completedProjects
            state.completedProjects = resp.completedProjects ? resp.completedProjects : []
        }
    },
    changeMenu:  (state, menu) => {
        state.selectedMenu = menu;
    },
    templateCategory:  (state, templateCategory) => {
        state.templateCategory = templateCategory;
    }
}
