export default {
  getProfile: (state) => state.profile,
  getStaticData: (state) => state.staticData,
  getDocumentSetup: (state) =>
    state.staticData.documentSetup ? state.staticData.documentSetup : [],
  isProfileLoaded: (state) => !!state.profile.name,
  haveDefaultDoc: (state) =>
    state.staticData.default_document >= 1 ? true : false,

  getSubscriptionTypes: (state) => state.subscriptionTypes,
  getDocuments: (state) => state.documents,
  getProjectDocuments: (state) => state.projectDocuments,
  getGlossary: (state) => state.glossary,
  getUsers: (state) => state.users,
  getRoles: (state) => state.roles,
  getMenuItems: (state) => {
    const boardMenus = JSON.parse(localStorage.getItem("board_menus"));
    return state.menuItems.length > 0 ? state.menuItems : boardMenus;
  },
  getCompany: (state) => state.company,
  getUserRoles: (state) => state.userRoles,
  getProjectRoles: (state) => state.projectRoles,
  getEmployees: (state) => state.employees,
  getMilestones: (state) => state.milestones,
  getSelectedMenu: (state) => state.selectedMenu,
  getSelectedBoard: (state) => state.selectedBoard,
  getCompletedProjects: (state) => state.completedProjects,
  getTemplateCategory: (state) =>
    state.templateCategory ? state.templateCategory : [],
};
