import { createRouter, createWebHistory } from "vue-router";
import Cookies from "js-cookie";

const routes = [
  // GUEST ROUTE
  {
    path: "/",
    component: require("@/views/layouts/guest/Index").default,
    children: [
      {
        path: "/",
        name: "login",
        component: () => import("@/views/guest/login/index"),
      },
      {
        path: "/login",
        redirect: "/",
      },
      {
        path: "/signup",
        name: "signup",
        component: () => import("@/views/guest/signup"),
      },
      {
        path: "/signup/invite/:param",
        name: "invite-user-signup",
        component: () => import("@/views/guest/signup"),
      },
      {
        path: "/password/reset",
        name: "password-reset",
        component: () => import("@/views/guest/password-reset/get-reset-link"),
      },
      {
        path: "/password/new-password/:token",
        name: "new-password",
        component: () =>
          import("@/views/guest/password-reset/set-new-password"),
      },
    ],
  },
  // AUTH ROUTE
  {
    path: "/",
    component: require("@/views/layouts/auth/Index").default,
    children: [
      {
        path: "404",
        name: "404",
        component: () => import("@/views/auth/404"),
      },
      {
        path: "user-profile/:id",
        name: "user-profile",
        component: () => import("@/views/auth/ProfileUpdate"),
      },
      {
        path: "board",
        name: "board",
        component: () => import("@/views/auth/board/Board"),
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@/views/auth/dashboard/Dashboard"),
      },
      {
        path: "workspace",
        name: "workspace",
        component: () => import("@/views/auth/workspace/Workspace"),
      },
      {
        path: "translation-pool",
        name: "translation-pool",
        component: () =>
          import("@/views/auth/translation-pool/TranslationPool"),
      },
      {
        // LIBRARY
        path: "/",
        name: "library_layout",
        component: () => import("@/views/auth/common/Index"),
        children: [
          {
            path: "library/template",
            name: "template",
            meta: { bredCrumbs: ["Template"] },
            component: () => import("@/views/auth/library/template/Template"),
          },
          {
            path: "library/create-template",
            name: "create-template",
            meta: {
              bredCrumbs: ["Template", "Create"],
              backURL: "/library/template",
            },
            component: () =>
              import("@/views/auth/library/template/CreateTemplate"),
            props: true,
          },
          {
            path: "library/update-template/:id",
            name: "update-template",
            meta: {
              bredCrumbs: ["Template", "Update And Review"],
              backURL: "/library/template",
            },
            component: () =>
              import("@/views/auth/library/template/CreateTemplate"),
            props: true,
          },
          {
            path: "library/template/history/:id",
            name: "template-history",
            meta: {
              bredCrumbs: ["Template", "Template History"],
              backURL: "/library/template",
            },
            component: () =>
              import("@/views/auth/library/template/TemplateHistory"),
            props: true,
          },
          {
            path: "library/template/items/:id",
            name: "template-content-items",
            meta: {
              bredCrumbs: ["Template", "Template Content Items"],
              backURL: "/library/template",
            },
            component: () =>
              import("@/views/auth/library/template/TemplateItems"),
            props: true,
          },
          {
            path: "library/content-library",
            name: "content-library",
            meta: { bredCrumbs: ["Content Library"] },
            component: () =>
              import("@/views/auth/library/content-library/ContentLibrary"),
          },
          {
            path: "library/content-library/:id",
            name: "create-content-library",
            meta: {
              bredCrumbs: ["Content Library", "Create"],
              backURL: "/library/content-library",
            },
            component: () =>
              import(
                "@/views/auth/library/content-library/CreateContentLibrary"
              ),
            props: true,
          },
          {
            path: "library/glossary",
            name: "glossary",
            meta: { bredCrumbs: ["Glossary"] },
            component: () => import("@/views/auth/library/glossary/Glossary"),
          },
          {
            path: "library/global-documents",
            name: "global-documents",
            meta: { bredCrumbs: ["Global Documents"] },
            component: () =>
              import("@/views/auth/library/global-documents/GlobalDocuments"),
          },
          {
            path: "library/external-documents",
            name: "external-documents",
            meta: { bredCrumbs: ["External Documents"] },
            component: () =>
              import(
                "@/views/auth/library/external-documents/ExternalDocuments"
              ),
          },
          {
            path: "library/external-documents/create",
            name: "create-external-documents",
            meta: {
              bredCrumbs: ["External Documents", "Create"],
              backURL: "/library/external-documents",
            },
            component: () =>
              import(
                "@/views/auth/library/external-documents/CreateExternalDocument"
              ),
          },
          {
            path: "library/external-documents/:id",
            name: "update-external-documents",
            props: true,
            meta: {
              bredCrumbs: ["External Documents", "Create"],
              backURL: "/library/external-documents",
            },
            component: () =>
              import(
                "@/views/auth/library/external-documents/CreateExternalDocument"
              ),
          },
          {
            path: "library/external-documents/history/:id",
            name: "external-documents-history",
            meta: {
              bredCrumbs: ["External Documents", "Create"],
              backURL: "/library/external-documents",
            },
            component: () =>
              import(
                "@/views/auth/library/external-documents/ExternalDocumentHistory"
              ),
            props: true,
          },
        ],
      },
      {
        // ADMINISTRATOR
        path: "/",
        name: "administrator_layout",
        component: () => import("@/views/auth/common/Index"),
        children: [
          {
            path: "administrator/companies",
            name: "companies",
            meta: { bredCrumbs: ["Companies"] },
            component: () =>
              import("@/views/auth/administrator/company/Company"),
          },
          {
            path: "administrator/create-company",
            name: "create-company",
            meta: {
              bredCrumbs: ["Company", "Create"],
              backURL: "/administrator/companies",
            },
            component: () =>
              import("@/views/auth/administrator/company/CreateCompany"),
          },
          {
            path: "administrator/company-details/:id",
            name: "company-details",
            meta: {
              bredCrumbs: ["Company", "Details"],
              backURL: "/administrator/companies",
            },
            component: () =>
              import("@/views/auth/administrator/company/CompanyDetails"),
          },
          {
            path: "administrator/company-users",
            name: "company-users",
            meta: { bredCrumbs: ["Users"] },
            component: () =>
              import("@/views/auth/administrator/user/CompanyUsers"),
          },
          {
            path: "administrator/setup/user-role",
            name: "user-role",
            meta: { bredCrumbs: ["Roles"] },
            component: () =>
              import("@/views/auth/administrator/setup/UserRole"),
          },
          {
            path: "administrator/setup/translations",
            name: "translations",
            meta: { bredCrumbs: ["Translations"] },
            component: () =>
              import("@/views/auth/administrator/setup/Translations"),
          },
          {
            path: "administrator/setup/user-permission/:id",
            name: "user-permission",
            meta: {
              bredCrumbs: ["User Role", "User Permission"],
              backURL: "/administrator/setup/user-role",
            },
            component: () =>
              import("@/views/auth/administrator/setup/permission/Permission"),
          },
          {
            path: "administrator/setup/milestone",
            name: "milestone",
            meta: { bredCrumbs: ["Milestone"] },
            component: () =>
              import("@/views/auth/administrator/setup/Milestone"),
          },
          {
            path: "administrator/setup/template-category",
            name: "template-category",
            meta: { bredCrumbs: ["Category"] },
            component: () =>
              import("@/views/auth/administrator/setup/TemplateCategory"),
          },
          {
            path: "administrator/user-access",
            name: "user-access",
            meta: { bredCrumbs: ["User Access"] },
            component: () => import("@/views/auth/administrator/UserAccess"),
          },
        ],
      },
      {
        path: "projects",
        name: "project",
        component: () => import("@/views/auth/project/Project"),
      },
      {
        path: "projects/create-project",
        name: "create-project",
        meta: { bredCrumbs: ["Project", "Create"], backURL: "/projects" },
        component: () => import("@/views/auth/project/CreateProject"),
      },
      {
        path: "task/submit/:id",
        name: "project-task",
        component: () =>
          import("@/views/auth/project/content-item/ProjectTask"),
      },
      {
        // PROJECT
        path: "/",
        name: "project_layout",
        component: () => import("@/views/auth/common/Index"),
        children: [
          {
            path: "projects/:id/project-dashboard",
            name: "project-dashboard",
            meta: {
              bredCrumbs: ["Projects", "Dashboard"],
              backURL: "/projects",
            },
            component: () => import("@/views/auth/project/ProjectDashboard"),
          },
          // SETUPS
          {
            path: "projects/:id/start-activity",
            name: "start-activity",
            meta: {
              bredCrumbs: ["Projects", "Start Activity"],
              backURL: "/projects",
            },
            component: () =>
              import("@/views/auth/project/setups/StartActivity"),
          },
          {
            path: "projects/:id/document-selection",
            name: "document-selection",
            meta: {
              bredCrumbs: ["Projects", "Document Section"],
              backURL: "/projects",
            },
            component: () =>
              import("@/views/auth/project/setups/DocumentSection"),
          },
          {
            path: "projects/:id/project-user",
            name: "project-user",
            meta: { bredCrumbs: ["Projects", "Users"], backURL: "/projects" },
            component: () => import("@/views/auth/project/setups/ProjectUser"),
          },
          {
            path: "projects/:id/role-manager",
            name: "role-manager",
            meta: {
              bredCrumbs: ["Projects", "Role Manager"],
              backURL: "/projects",
            },
            component: () => import("@/views/auth/project/setups/RoleManager"),
          },
          // OVERVIEW
          {
            path: "projects/:id/identification",
            name: "identification",
            meta: {
              bredCrumbs: ["Projects", "Identification"],
              backURL: "/projects",
            },
            component: () =>
              import("@/views/auth/project/overview/ProjectIdentification"),
          },
          {
            path: "projects/:id/project-glossary",
            name: "project-glossary",
            meta: {
              bredCrumbs: ["Projects", "Glossary"],
              backURL: "/projects",
            },
            component: () =>
              import("@/views/auth/project/overview/ProjectGlossary"),
          },
          {
            path: "projects/:id/project-milestone",
            name: "project-milestone",
            meta: {
              bredCrumbs: ["Projects", "Milestone"],
              backURL: "/projects",
            },
            component: () =>
              import("@/views/auth/project/overview/ProjectMilestone"),
          },
          // DOCUMENTS
          {
            path: "projects/:id/documents",
            name: "documents",
            meta: {
              bredCrumbs: ["Projects", "Documents"],
              backURL: "/projects",
            },
            component: () =>
              import("@/views/auth/project/documents/ProjectDocuments"),
          },
          {
            path: "projects/:id/document/:doc/view",
            name: "document-view",
            meta: {
              bredCrumbs: ["Projects", "Documents"],
              backURL: "/projects",
            },
            component: () =>
              import("@/views/auth/project/documents/ProjectDocumentView"),
          },
          {
            path: "projects/:id/document/:doc/preview",
            name: "document-preview",
            meta: {
              bredCrumbs: ["Projects", "Documents"],
              backURL: "/projects",
            },
            component: () =>
              import("@/views/auth/project/documents/ProjectDocumentPreview"),
          },
          // CONTENT ITEM
          {
            path: "projects/:id/contents",
            name: "contents",
            meta: {
              bredCrumbs: ["Projects", "Content Items"],
              backURL: "/projects",
            },
            component: () =>
              import("@/views/auth/project/content-item/ProjectContentItems"),
          },
          {
            path: "projects/:id/project-task/:content_id",
            name: "content-item-project-task",
            meta: {
              bredCrumbs: ["Project", "Content Items"],
              backURL: "/projects",
            },
            component: () =>
              import("@/views/auth/project/content-item/ProjectTask"),
          },
          // DELIVERABLE MATRIX
          {
            path: "projects/:id/deliverable-matrix",
            name: "deliverable-matrix",
            meta: {
              bredCrumbs: ["Projects", "Deliverable Matrix"],
              backURL: "/projects",
            },
            component: () => import("@/views/auth/project/DeliverableMatrix"),
          },
          {
            path: "projects/:id/project_risks",
            name: "project_risks",
            meta: { bredCrumbs: ["Projects", "Risks"], backURL: "/projects" },
            component: () => import("@/views/auth/404"),
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//For middleware route
router.beforeEach((to, from, next) => {
  const publicPages = [
    "login",
    "signup",
    "password-reset",
    "new-password",
    "invite-user-signup",
  ];
  const authNotRequired = !publicPages.includes(to.name);
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = Cookies.get("access_token");

  if (authRequired && !loggedIn) {
    next({ name: "login" });
  } else {
    if (!authNotRequired && loggedIn) {
      next({ name: "board" });
    }
    if (typeof to.name == "undefined") {
      next({ name: "404" });
    }
    next();
  }
});

export default router;
