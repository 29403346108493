import axios from 'axios';
import authHeader from "@/common/auth/auth-header";

const state = {
  notifications: [],
  unreadCount: 0,
};

const getters = {
  allNotifications: (state) => state.notifications,
  unreadCount: (state) => state.unreadCount,
};

const actions = {
  async fetchNotifications({ commit }, payload) {
    try {
      const projectId = payload.project_id;
    const companyId = payload.company_id;

    if (!projectId || !companyId) {
      console.error('Invalid payload:', payload);
      return; // Exit early if payload is invalid
    }

    const response = await axios.get(`notifications?project_id=${projectId}&company_id=${companyId}`, authHeader());
      console.log(response, "response.data");
      commit('setNotifications', response.data);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  },

  async markAsRead({ commit }, notificationId) {
    try {
      await axios.post(`notifications/mark-as-read`, { id: notificationId },  authHeader());
      commit('markAsRead', notificationId);
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  },
};

const mutations = {
    setNotifications: (state, notifications) => {
        console.log(notifications, "notifications");
        state.notifications = notifications;
        state.unreadCount = notifications.filter(notification => !notification.read_at).length; 
      },
      
      markAsRead: (state, notificationId) => {
        const notification = state.notifications.find(n => n.id === notificationId);
        if (notification) {
          notification.read_at = new Date(); 
          state.unreadCount = state.notifications.filter(n => !n.read_at).length; 
        }
      },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

