import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {store} from './store'
import axios from 'axios'
import VueAxios from 'vue-axios' //tested
import VueToast from 'vue-toast-notification' //tested
import 'vue-toast-notification/dist/theme-sugar.css'
import VueSweetalert2 from 'vue-sweetalert2' //tested
import 'sweetalert2/dist/sweetalert2.min.css'
// import "bootstrap/dist/css/bootstrap.min.css" //tested
import moment from "moment"; //tested
const vueConfig = require('vue-config') //tested
const configs = require('./config/api.json')
import VueCookies from 'vue3-cookies' //tested
import {library} from "@fortawesome/fontawesome-svg-core"
import {fas} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import Editor from '@tinymce/tinymce-vue';
import Cookies from "js-cookie";

library.add(fas) //tested
import jQuery from 'jquery'

global.jQuery = jQuery
global.$ = jQuery //tested

const app = createApp(App);

//STATE MANAGEMENT
app.use(store);

//ROUTER
app.use(router);

//SET BASE URL for Axios
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
app.use(VueAxios, axios).provide('axios', axios)

//FOR FULL SCREEN
import VueFullscreen from 'vue-fullscreen'

//VUE TOAST
app.use(VueToast, {
    position: 'bottom-left',
    duration: 9000
}).provide('toast', app.config.globalProperties.$toast);

app.use(VueSweetalert2);
app.config.globalProperties.$moment = moment
app.config.globalProperties.$configs = configs
app.use(vueConfig, configs)
app.use(VueCookies).provide('cookies', app.config.globalProperties.$cookies)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('editor', Editor)
app.use(VueFullscreen)

window.vueConfig = configs;
window.jsCookie = Cookies;
window.backendUrl = process.env.VUE_APP_BACKEND_URL;

router.isReady().then(() => {
  localStorage.removeItem("locale_change_key");
});

//app.mount('#app');
const storedLocale = localStorage.getItem('locale') || 'en';
localStorage.setItem('locale', storedLocale);

axios.get(`/translations/${storedLocale}`)
  .then(response => {
    store.commit('setTranslations', response.data);  
    store.commit('setLocale', storedLocale); 
    app.mount('#app');
  })
  .catch(error => {
    console.error('Error fetching translations:', error);
    app.mount('#app');  
  });
