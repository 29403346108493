import axios from "axios";
import authHeader from "@/common/auth/auth-header";

export default {
    getContentItem: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.get(`${vueConfig.Library.ContentItemByIdOrIdentifierEndPoint}/${payload}`, authHeader())
                .then(resp => {
                    resolve(resp);
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                })
        })
    },
    updateContentItem: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.put(`${vueConfig.Library.ContentItemsEndPoint}/${payload.action}`,
                payload.formData, authHeader())
                .then(resp => {
                    resolve(resp);
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                })
        })
    },
    storeContentItem: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.put(`${vueConfig.Library.ContentItemsEndPoint}`,
                payload.formData, authHeader())
                .then(resp => {
                    resolve(resp);
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                })
        })
    },

    storeTemplateFrom: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(vueConfig.Library.TemplatesEndPoint,
                payload.formData, authHeader())
                .then(resp => {
                    resolve(resp);
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                })
        })
    },

    updateTemplateFrom: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.put(`${vueConfig.Library.TemplatesEndPoint}/${payload.action}`,
                payload.formData, authHeader())
                .then(resp => {
                    resolve(resp);
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                })
        })
    },

    getTemplate: ({commit}, payload) => {
        let setParams = {
            params: {...payload.params}
        }
        return new Promise((resolve, reject) => {
            axios.get(`${vueConfig.Library.TemplatesEndPoint}/${payload.action}`,
                Object.assign(setParams, authHeader()))
                .then(resp => {
                    resolve(resp);
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                })
        })
    },

    getDiscussionBoard: ({commit}, payload) => {
        let setParams = {
            params: {...payload}
        }
        return new Promise((resolve, reject) => {
            axios.get(`${vueConfig.Library.DiscussionBoardEndPoint}`,
                Object.assign(setParams, authHeader()))
                .then(resp => {
                    resolve(resp);
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                })
        })
    },

    storeDiscussionBoard: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(vueConfig.Library.DiscussionBoardEndPoint,
                payload, authHeader())
                .then(resp => {
                    resolve(resp);
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                })
        })
    },


    getProjectDiscussionBoard: ({commit}, payload) => {
        let setParams = {
            params: {...payload}
        }
        return new Promise((resolve, reject) => {
            axios.get(`${vueConfig.Project.DiscussionBoardEndPoint}`,
                Object.assign(setParams, authHeader()))
                .then(resp => {
                    resolve(resp);
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                })
        })
    },

    storeProjectDiscussionBoard: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(vueConfig.Project.DiscussionBoardEndPoint,
                payload, authHeader())
                .then(resp => {
                    resolve(resp);
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                })
        })
    },

    getTemplateApprovers: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.post(payload.action,
                payload.params, authHeader())
                .then(resp => {
                    resolve(resp);
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                })
        })
    },
    storeTemplateApprover: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.put(`${vueConfig.Library.TemplateApproversEndPoint}/${payload.action}`,
                payload.formData, authHeader())
                .then(resp => {
                    resolve(resp);
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                })
        })
    },

    setTemplateContentItems:  ({commit}, payload) => {
        commit('setTemplateContentItems', payload);
    },

    updateExternalDocument: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.put(`${vueConfig.Library.ExternalDocumentsEndPoint}/${payload.action}`,
                payload.formData, authHeader())
                .then(resp => {
                    resolve(resp);
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                })
        })
    },

    reOpenExternalDocument: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.put(`${vueConfig.Library.ExternalDocumentsReOpenEndPoint}/${payload.action}`,
                payload.formData, authHeader())
                .then(resp => {
                    resolve(resp);
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                })
        })
    },
};
