<template>

    <!-- HEADER -->
    <top-header/>

    <router-view></router-view>

    <!--  CALL AUTO LOGOUT -->
    <auto-logout></auto-logout>

</template>

<script>

    import TopHeader from "./common/TopHeader";
    import BottomFooter from "./common/BottomFooter";
    import AutoLogout from "@/common/components/AutoLogout";

    export default {
        name: "index",
        components: {BottomFooter, TopHeader, AutoLogout},
    }
</script>

<style scoped>

</style>
