import Cookies from 'js-cookie'

export default function authHeader(param = null) {
    // const token = localStorage.getItem("access_token")
    const token = Cookies.get("access_token")
    if (token) {
        // return { headers: { Authorization: "Bearer " + token } }
        return { headers: { Authorization: token }, data: param }
    } else {
        return {}
    }
}
