import axios from "axios";
import authHeader from "@/common/auth/auth-header";

export default {
    getProject: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.get(`${vueConfig.Project.ProjectEndPoint}/${payload}`, authHeader())
                .then(resp => {
                    commit('setProjectData', resp.data);
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    checkProjectInvitationById: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            axios.get(`${payload.url}?project_id=${payload.project_id}&company_id=${payload.company_id}`, authHeader())
                .then(resp => {
                    commit('setProjectInvitationData', resp.data);
                    resolve(resp);
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }
}
