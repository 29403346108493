import Errors from "../errors";

export default {
    formRequest: (state) => {
        state.status = 'loading'
    },
    formError: (state, err) => {
        if (err) {
            let errors = err.data.errors ? err.data.errors : {}

            if (err.data.message === 'Unauthenticated.') location.reload();

            errors.message = [err.data.message]

            state.status = 'error'
            state.errors.record(errors)
        } else {
            state.errors = new Errors()
        }
    },
    formSuccess: (state) => {
        state.status = 'success'
        state.errors = new Errors()
    }
}
