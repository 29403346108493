<template>
    <footer class="py-3 w-100 mb-0 align-self-end mt-auto">
        <div class="site-footer col-12">
            <p class="text-center">App version: 0.12, ACME-HERZING MDC GmbH, Developed by
                <a href="https://kaz.com.bd/" target="_blank">KAZ SOFTWARE</a>
            </p>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "BottomFooter"
    }
</script>

<style scoped>

</style>
