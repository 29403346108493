import Errors from "../errors";


export default {
    loginError: (state, err) => {
        if (err) {
            state.errors = err
        } else {
            state.errors = new Errors()
        }
    },
    loginSuccess: (state, resp) => {
        state.status = 'success'
        state.errors = new Errors()
        state.access_token = resp.access_token
        state.refresh_token = resp.refresh_token
    },
    logoutRequest: (state) => {
        state.access_token = ''
        state.staticData = {}
    }
}