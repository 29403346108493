import {createStore} from 'vuex'
import axios from 'axios'
import Cookies from 'js-cookie';

//MODULES
import login from "@/store/modules/auth";
import formHandler from "@/store/modules/formHandler";
import user from "@/store/modules/user";
import dashboard from '@/store/modules/dashboard';
import library from '@/store/modules/library';
import project from '@/store/modules/project';
import notifications from './modules/notifications';

// CREATE STORE
export const store = new createStore({
    state: {
        pageLoader: false,
        locale: 'en',
        translations: {},
    },
    mutations: {
        setLocale(state, locale) {
          state.locale = locale;
        },
        setTranslations(state, translations) {
          state.translations = translations;
        },
      },
      actions: {
        fetchTranslations({ commit }, locale) {
          axios.get(`/translations/${locale}`).then(response => {
            commit('setTranslations', response.data);
            commit('setLocale', locale);
          });
        },
      },
    modules: {
        login,
        formHandler,
        user,
        dashboard,
        library,
        project,
        notifications
    }
});

//PAGE LOADER INTERCEPTOR FOR HTTP REQUESTS
axios.interceptors.request.use(
    (request) => {
        store.state.pageLoader = true;
        return request
    }, 
    (err) => {
        store.state.pageLoader = false;
        return Promise.reject(err)
    }
)

axios.interceptors.response.use(
    (response) => {
        store.state.pageLoader = false;
        return response
    }, 
    async (err) => {
        // CALL REFRESH TOKEN ACTION
        const unInterceptedAxiosInstance = axios.create()
        const originalRequest = err.config;
        if(err.response && err.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true
            let refreshToken = Cookies.get("refresh_token")
            try {
                const resp = await store.dispatch("refreshAction", { formData: refreshToken, action: "refresh" })
                
                if (resp.status === 200) {
                    store.state.pageLoader = false
                    originalRequest.headers['Authorization'] = Cookies.get("access_token")
                    return unInterceptedAxiosInstance(originalRequest)
                }
            } catch (_error) {
                return Promise.reject(_error)
            }
        }

        store.state.pageLoader = false;
        return Promise.reject(err)
    }
)
