<template>

    <div v-if="showMessage">
        <!-- Modal -->
        <div class="full-shadow">
            <div class="modal fade show" id="addMilestoneModal" tabindex="-1" aria-labelledby="addMilestoneModalLabel" aria-hidden="true"
                 style="display: block">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content border-0 shadow-sm">
                        <div class="modal-header bg-danger">
                            <h5 class="text-white">
                            <span class="me-2">
                                <font-awesome-icon :icon="['fas', `exclamation-triangle`]"></font-awesome-icon>
                            </span>
                                Alert!
                            </h5>
                        </div>
                        <div class="modal-body">
                            <h5 class="text-center text-secondary py-3">Are you still working on this page?</h5>
                        </div>
                        <div class="modal-footer text-center" style="display: unset !important;">
                            <button class="btn btn-success rounded-pill w-75" data-bs-dismiss="modal">
                            <span class="me-2">
                                <font-awesome-icon :icon="['fas', `check-circle`]"></font-awesome-icon>
                            </span>
                                STAY ONLINE
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Modal -->
    </div>

</template>

<script>
    export default {
        name: "AutoLogout",
        data() {
            return {
                events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
                showMessage: false,
                warningTimer: null,
                logoutTimer: null,
            }
        },
        mounted() {
            this.events.forEach(function (event) {
                window.addEventListener(event, this.resetTimer)
            }, this)
            this.setTimers()
        },
        destroyed() {
            this.events.forEach(function (event) {
                window.removeEventListener(event, this.resetTimer)
            }, this)
            this.resetTimer()
        },
        methods: {
            setTimers() {
                this.warningTimer = setTimeout(this.warningMessage, 10 * 60 * 1000) // call after 10 minutes 10 * 60 * 1000
                this.logoutTimer = setTimeout(this.logoutUser, 12 * 60 * 1000) // call after 12 minutes 12 * 60 * 1000

                this.showMessage = false
            },
            warningMessage() {
                this.showMessage = true
            },
            logoutUser() {
                this.$store.dispatch('logoutAction');
            },
            resetTimer() {
                clearTimeout(this.warningTimer)
                clearTimeout(this.logoutTimer)
                this.setTimers()
            },

        }
    }
</script>

<style scoped>

    .full-shadow {
        min-width: 100vw;
        min-height: 100vh;
        background: rgba(0,0,0,0.5);
        position: absolute;
        top: 0;
    }

</style>