import axios from "axios";

export default {
    formAction: ({ commit, dispatch }, payload) => {
        const formEndpoint = payload.action;
        const formData = payload.formData

        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post(formEndpoint, formData);
                commit('formSuccess')

                if (payload.userReload)
                    dispatch('userAction')

                if (payload.staticDataReload)
                    dispatch('staticDataRequest')


                resolve(response)
            } catch (err) {
                // console.log(err.response,'respadasdk error')

                commit('formError', err.response)
                reject(err)
            }
        })
    }
}
