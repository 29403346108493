import axios from "axios";
import authHeader from "@/common/auth/auth-header";

export default {
    getDashboardData: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            let setParams = {
                params: {...payload}
            }
            axios.get(vueConfig.Dashboard.DashboardDataEndPoint,
                Object.assign(setParams, authHeader()))
                .then(resp => {
                    commit('setDashBoardData', resp.data);
                    resolve(resp)
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                })
        })
    },
};
