export default {
    status: '',
    profile: {},
    staticData: {},
    selectedBoard: {},
    selectedMenu: {},

    subscriptionTypes: [],
    documents: [],
    glossary: [],
    users: [],
    roles: [],
    menuItems: [],
    company: "",
    userRoles: [],
    projectRoles: [],
    employees: [],
    milestones: [],
    completedProjects: [],
    templateCategory: []
}
