function tablePermissionCheck(menuActions, type, tableAction = {}) {
    let action = menuActions.find(item => item.name === type);
    return {
        ...tableAction,
        show: action ? action.checked : false
    };
}

function updatePermission(menuActions) {
    let action = {
        title: 'Edit',
        icon: 'edit',
        type: 'edit',
        show: true
    };
    let result = tablePermissionCheck(menuActions, 'update', action);
    return result ? result : null;
}

function deletePermission(menuActions) {
    let action = {
        title: 'Delete',
        icon: 'trash-alt',
        type: 'delete',
        show: true
    };
    let result = tablePermissionCheck(menuActions, 'delete', action);
    return result ? result : null;
}

function createPermission(menuActions) {
    let result = tablePermissionCheck(menuActions, 'create');
    return result ? result : null;
}

function viewPermission(menuActions) {
    let result = tablePermissionCheck(menuActions, 'view');
    return result ? result.show : false;
}

function statusPermission(menuActions) {
    let result = tablePermissionCheck(menuActions, 'status');
    return result ? result : null;
}

export {updatePermission, deletePermission, createPermission, viewPermission, statusPermission};
