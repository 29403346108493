<template>
    <section class="purple-bg-full w-100 vh-100 p-xxl-5 p-xl-5 p-lg-5 p-md-2 p-sm-2 p-2 m-auto d-flex">
        <div class="container custom-container m-auto">
            <div class="row justify-content-center">
                <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2
                    d-xxl-block d-xl-none d-lg-none d-md-none d-none"></div>
                <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 d-flex bg-white">
                    <div class="w-100 w-100 p-xxl-5 p-xl-5 p-lg-5 p-md-2 p-sm-2 p-2 text-center my-auto">
                        <img :src="require('@/assets/img/logo-herzing.png')" alt="Herzing" class="site-logo"/>
                    </div>
                </div>
                <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 d-flex border-start bg-white">

                    <router-view></router-view>

                </div>

                <div class="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2
                    col-2 d-xxl-block d-xl-none d-lg-none d-md-none d-none"></div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "GuestLayout"
    }
</script>

