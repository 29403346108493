import axios from "axios";
import Cookies from 'js-cookie'
import router from '@/router/index'
import authHeader from "@/common/auth/auth-header";

export default {
    loginAction: ({commit, dispatch}, payload) => {

        const loginUrl = payload.action;
        const remember = payload.remember ? payload.remember : false;
        const formData = payload.formData
        return new Promise(async (resolve, reject) => {
            try {
                const resp = await axios.post(loginUrl, formData);
                const response = resp.data.data;

                const access_token = 'Bearer ' + response.access_token;
                const refresh_token = response.refresh_token;

                axios.defaults.headers.common['Authorization'] = access_token

                Cookies.set("access_token", access_token, {expires: remember ? 365 : response.expires_in})
                Cookies.set("refresh_token", refresh_token)

                commit('loginSuccess', {access_token, refresh_token})
                await dispatch('userAction')
                if(response.superAdmin){
                    const board = {
                        logo: require('@/assets/img/logo-herzing.png'),
                        company_name: 'Super Admin',
                        id: 0
                    }
                    const company = {
                        company_id: null
                    }
                    localStorage.setItem('selected_board', JSON.stringify(board));
                    commit('selectedBoard', board);
                    await dispatch('getMasterData');
                    const menu = await dispatch('getBoardUserMenu', {company: company, route: '/dashboard'});
                    await dispatch('getDashboardData', company);
                    await dispatch('getTemplateCategory', company);
                    router.push('/dashboard');
                } else {
                    router.push('/board');
                }

                resolve(resp)
            } catch (err) {
                Cookies.remove('access_token')
                Cookies.remove('refresh_token')

                commit('loginError', err.response)
                reject(err.response)
            }
        })
    },
    logoutAction: ({dispatch}) => {

        return new Promise(async (resolve, reject) => {
            await axios.get("logout", authHeader())
            .then((response) => {
                dispatch('removeAll')
                resolve(response)
            })
            .catch((error) => {
                dispatch('removeAll')
                reject(error.response)
            })
        })
    },
    removeAll: ({dispatch}) => {
        localStorage.clear()
        dispatch('removeCommonLocalStorageItem')
        Cookies.remove('access_token')
        Cookies.remove('refresh_token')
        axios.defaults.headers.common['Authorization'] = ""
        location.reload();
    },
    refreshAction: ({commit, dispatch}, payload) => {
        const formData = {
            refresh: payload.formData
        }

        return new Promise(async (resolve, reject) => {
            const unInterceptedAxiosInstance = axios.create()
            await unInterceptedAxiosInstance.post(payload.action, formData)
            .then((resp) => {
             
                const response = resp.data;
                const access_token = 'Bearer ' + response.access_token;
                const refresh_token = response.refresh_token;

                unInterceptedAxiosInstance.defaults.headers.common['Authorization'] = access_token

                Cookies.set("access_token", access_token, {expires: payload.remember ? 365 : response.expires_in})
                Cookies.set("refresh_token", refresh_token)

                commit('loginSuccess', {access_token, refresh_token})
                dispatch('userAction')

                resolve(resp)
               
            })
            .catch((error) => {
                reject(error)
            })
        })
    },

};
