<template>
    <router-view></router-view>

    <loader v-if="$store.state.pageLoader"></loader>
</template>


<style src="@/assets/css/bootstrap.min.css"></style>
<style src="@vueform/multiselect/themes/default.css"></style>
<style src="@/assets/css/style.css"></style>

<script>
import Cookies from "js-cookie";

import("@/assets/js/bootstrap.bundle.min.js")
import("@/assets/js/jquery.steps.js")
import Loader from "./common/components/loader/Index";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import axios from "axios";

export default {
    components: {Loader},
    setup() {
        const store = useStore();
        const router = useRouter();
        const loggedIn = Cookies.get("access_token");

        const fetchTranslation = async (storedLocale) => {
            axios.get(`/translations/${storedLocale}`)
            .then(response => {
                store.commit('setTranslations', response.data);  
                store.commit('setLocale', storedLocale); 
                
            })
            .catch(error => {
                console.error('Error fetching translations:', error);
            });
        };
        
        if (loggedIn) {
            store.dispatch('userAction');
            const selectedBoard = JSON.parse(localStorage.getItem('selected_board'));
            const selectedMenu = JSON.parse(localStorage.getItem('selected_menu'));
            const userInfo = JSON.parse(localStorage.getItem('user_info'));

            if (userInfo) {
                if(userInfo.locale) {
                    localStorage.setItem('locale', userInfo.locale); 
                }
            }
            if (selectedBoard) {
                if(selectedBoard.locale) {
                    if (!userInfo.locale) {
                        localStorage.setItem('locale', userInfo.locale); 
                        const localeChangeKey = localStorage.getItem("locale_change_key");
                        const storedLocale = localStorage.getItem('locale') || 'en';
                        if(!localeChangeKey  || storedLocale !== selectedBoard.locale) {
                            localStorage.setItem('locale', selectedBoard.locale);
                            fetchTranslation(selectedBoard.locale);
                        }
                    }
                }
                if (selectedMenu) {
                    store.dispatch('changeMenu', selectedMenu);
                }
                let payload = {
                    board: selectedBoard,
                    route: ''
                };
                store.dispatch('changeBoard', payload);
            } else {
                store.dispatch('removeCommonLocalStorageItem');
                router.push(`/board`);
            }
        }
    },
}
</script>
<style>
.multiselect-tag {
    background: #5c76fa !important;
}

.is-active {
    box-shadow: unset !important;
}
</style>

